<template lang="html">
    <div v-if="isEnded">
      Ended.
    </div>
  
    <div v-else>
      <div>{{ duration }}</div>
    </div>
  </template>
  
  <script>
import moment from 'moment';

  export default {
    props: {
      endDate: {
        type: Date // Date.parse(this.endDate)
      }
    },
    data () { 
      return {
        startDate: null,
        endDateMoment: null,
        duration: null,
        days: null,
        hours: null,
        minutes: null,
        seconds: null,
        isEnded: null,
        now: null
      }
    },
    methods: {
      updateRemaining (distance) {
        this.now = moment();
        this.endDateMoment = moment(this.endDate) 
        this.duration = moment(this.now.diff(this.endDateMoment)).format('mm:ss');
              // let duration = moment(now.diff(end)).format('mm:ss');

        this.days = Math.floor(distance / (1000 * 60 * 60 * 24))
        this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
        this.seconds = Math.floor((distance % (1000 * 60)) / 1000)
      },
      
      tick () {
        const currentTime = new Date()
        const distance = Math.max(this.endDate - currentTime, 0)
        this.updateRemaining(distance)
        
        if (distance === 0) {
          clearInterval(this.timer)
          this.isEnded = true
        }
      }
    },
    
    mounted () {
      this.tick()
      this.timer = setInterval(this.tick.bind(this), 1000)
    },
    
    destroy () {
      clearInterval(this.timer)
    }
  }
  </script>